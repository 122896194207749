.animate-gradient-x {
  background-image: conic-gradient(red, orange, yellow, lime, aqua, blue, violet, magenta, red);
  background-size: 200% 200%;
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}